export function slidetoggle() {
    var EL = document.getElementById( this.dataset.slidetoggle ),
    ch = EL.clientHeight,
    sh = EL.scrollHeight,
    isCollapsed = !ch,
    noHeightSet = !EL.style.height,
    prevSibling = EL.previousElementSibling;

    EL.style.height = (isCollapsed || noHeightSet ? sh : 0) +"px";
    if(noHeightSet) return slidetoggle.bind(this)();

    if (!prevSibling.classList.contains('open')) {
        prevSibling.classList.add('open');
        return;
    } else {
        prevSibling.classList.remove('open');
        return;
    }
}

export function linkIsInternal(link) {
    return (link.host == window.location.host);
}

// var siteURL = "http://" + top.location.host.toString();
// var $internalLinks = $("a[href^='"+siteURL+"'], a[href^='/'], a[href^='./'], a[href^='../'], a[href^='#']");
