import $ from 'jquery';
const hamburger = document.querySelector('.hamburger');
const siteMenu = document.querySelector('.site-menu');
const lawMenuButton = document.querySelector('.site-menu__law-menu-button');
const lawMenu = document.querySelector('.site-menu__law-menu');

hamburger.addEventListener('click', function() {
    hamburger.classList.toggle('is-active');
    siteMenu.classList.toggle('open');
    if (siteMenu.classList.contains('open')) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
});

lawMenuButton.addEventListener('click', function() {
    lawMenu.classList.toggle('open');
});
