import { slidetoggle } from '../lib/helpers.js';

const AccordionHandler = () =>
{
    const accordionHeader = document.querySelectorAll('.accordion__header');

    if (accordionHeader != undefined && accordionHeader.length > 0) {
        accordionHeader.forEach(accordion => {
            accordion.addEventListener('click', slidetoggle);
        });
    }
}

export default AccordionHandler;
