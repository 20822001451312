import { slidetoggle } from '../lib/helpers.js';
const employeeCards = document.querySelectorAll('.employee__card');
const filterListChosen = document.querySelector('.employee__filter-chosen');
const filterListItems = Array.from(document.querySelectorAll('.employee__filter-list-item'));
const filterList = document.querySelector('.employee__filter-list');
let animationDelay = 0;

const filterEmployees = (lawArea) => {
    employeeCards.forEach((employee, index) => {
        const employeeLawAreaString = employee.getAttribute("data-area");
        const employeeAreas = employeeLawAreaString.split(",");
        employee.classList.add('animate-out');

        setTimeout(() => {
            if (employeeAreas.includes(lawArea)) {
                employee.classList.remove('hide');
                animationDelay += 50;

                setTimeout(() => {
                    employee.classList.remove('animate-out');
                }, 200);

            } else {
                employee.classList.add('hide');
            }
        }, 300);
    });
}

filterListItems.forEach((button, index) => {
    button.addEventListener('click', (event) => {
        const _this = event.currentTarget;
        const lawArea = _this.getAttribute("data-area");
        animationDelay = 0;

        filterListItems.filter((button) => {
            if (button != _this) {
                button.classList.remove('active');
            } else {
                _this.classList.add('active');
            }
        });

        _this.classList.add('active');
        filterEmployees(lawArea);
    });
});

if (filterListChosen) {
    filterListChosen.addEventListener('click', slidetoggle);
}
