import '@babel/polyfill';
import './lib/polyfills.js';
import './lib/picturefill.js';
import { slidetoggle, linkIsInternal } from './lib/helpers.js';

// Components
import './components/SiteNavigation.js';
import './components/EmployeeFilter.js';
import './components/AccordionHandler.js';

const slideToggleMenu = document.querySelectorAll("[data-slidetoggle]");

if (slideToggleMenu) {
    slideToggleMenu.forEach(menu => {
        menu.addEventListener('click', slidetoggle);
    });
}

// const links = document.getElementsByTagName('a');
// const siteHolder = document.querySelector('.site-holder');
//
// for (var i = 0; i < links.length; i++) {
//     if (linkIsInternal(links[i])) {
//         links[i].addEventListener('click', (e) => {
//             // setTimeout( () => { window.location = e.currentTarget.href }, 500 );
//             siteHolder.classList.add('page-transition__out');
//         });
//     }
// }

// document.addEventListener("DOMContentLoaded", function()
// {
//     siteHolder.classList.remove('page-transition__out');
// });
